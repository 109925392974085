<template>
    <form
        class="space-y-6"
        @submit.prevent="submit"
    >
        <h3 class="subtitle">
            Créer un rappel
        </h3>

        <UiInputDate
            v-model="formData.start_date"
            label="Date"
            :min="today"
            name="start_date"
            required
            :errors="errors"
        />

        <UiTextarea
            v-model="formData.description"
            label="Titre"
            name="description"
            required
            :errors="errors"
        />

        <UiSelect
            v-model="formData.registrations_uuid"
            placeholder="Rechercher parmi les enfants"
            :options="registeredRegistrationOptions"
            label="Enfants concernés"
            :errors="errors"
            name="registrations_uuid"
            multiple
        />

        <UiSelect
            v-model="formData.staff_uuid"
            label="Personnel à notifier"
            :options="staffWithUserOptions"
            name="staff"
            multiple
            :errors="errors"
            help="Laissez vide pour notifier tous les membres du personnel. Seul le personnel avec un compte utilisateur sera notifié."
        />
        <div class="text-right">
            <button
                class="btn btn-primary"
                :disabled="isLoading"
                :class="{ loading: isLoading }"
                type="submit"
            >
                Valider
            </button>
        </div>
    </form>
</template>

<script>
import dayjs from 'dayjs'
import { loadingMixin } from 'abcreche-ui'
import staffOptions from '~/mixins/staff/staffOptions'
import EventRepository from '~/repositories/Creche/EventRepository.js'
import registrationsListMixin from '~/mixins/registrations/registrationsListMixin.js'

export default {
    mixins: [loadingMixin, registrationsListMixin, staffOptions],

    props: {
        registration: {
            type: Object,
            default: null
        },
        date: {
            type: [String, Date, Object],
            default: null
        }
    },

    emits: ['created'],

    data () {
        return {
            formData: {
                description: '',
                start_date: this.date ? dayjs(this.date).format('YYYY-MM-DD') : null,
                start_time: '00:00',
                registrations_uuid: [],
                staff_uuid: [],
                notify: true
            }
        }
    },

    computed: {
        today () {
            return dayjs().format('YYYY-MM-DD')
        }
    },

    created () {
        this.fetchRegisteredRegistrations()
    },

    methods: {
        submit () {
            this.load()
            const payload = this.formData
            payload.registrations_uuid = this.formData.registrations_uuid
            payload.staff_uuid = this.formData.staff_uuid
            EventRepository.create(payload)
                .then((response) => {
                    this.$emit('created', response.data)
                    this.done('Le rappel a été créé.')
                })
                .catch(error => this.doneWithErrors(error))
        }
    }
}
</script>
